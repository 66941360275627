import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { DebounceInput } from "react-debounce-input";
import { Creators as AcaoAdmCreators } from "../../store/ducks/acaoAdm";

import {
  dowloadFile,
  formataMoney,
  formatCurrentMoney,
  tipoFinanciamento,
} from "../../utils/helper";
import { api } from "../../services/api";

import Button from "../../components/button";
import SelectCustom from "../../components/SelectCustom";

import Modal from "../modal";

import { Container } from "./styles";
import { toast } from "react-toastify";
import FormAcao from "../../pages/sistema/formAcao";

function FiltroContrato(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingCarga, setLoadingCarga] = useState(false);
  const [loadingAcao, setLoadingAcao] = useState(false);
  const [loadingContratos, setLoadingContratos] = useState(false);
  const [ptsCartaoMit, setPtsCartaoMit] = useState(false);
  const [exportFuncionarios, setExportFuncionarios] = useState(false);
  const [contratosCartaoMit, setContratosCartaoMit] = useState(false);
  const [actionData , setActionData] = useState({});
  const [valueFinanciamentoValor, setValueFinanciamentoValor] = useState("");
  const { location } = history;
  const itemselect = location?.itemselect;
  const { setItemSelect, select = [], placeholder } = props;

  const { data } = useSelector((state) => state.cargaAcaoAdm);
  const { modal_acao_update } = useSelector(
    (state) => state.acaoAdm
  );
  const { acao_id } = useSelector((state) => state.acaoAdm);

  const { acao_type, admList } = data;

  useEffect(() => {
    (async () => {
      setLoadingAcao(true);
      if(!acao_id){
        setLoadingAcao(false);
        return;
      }
      let action = await api.get(`acao/${acao_id}`);
      if(action?.data){
        setActionData({
          id: action?.data?.id,
          nome: action?.data?.nome,
          start_period: action?.data?.start_period,
          end_period: action?.data?.end_period,
          action_type: action?.data?.type,
          financiamento_valor_max: action?.data?.financiamento_valor_max
        });
      }
      setLoadingAcao(false);
    })();
  }, [acao_id]);

  async function exportarCarga() {
    setLoadingCarga(true);

    await dowloadFile(`/acao/${acao_id}/export/products`);

    setLoadingCarga(false);
  }

  async function exportarContratos() {
    setLoadingContratos(true);

    await dowloadFile(`acao/${acao_id}/export/contracts`);

    setLoadingContratos(false);
  }

  async function exportarContratosSeguro() {
    setLoadingContratos(true);

    await dowloadFile(`acao/${acao_id}/export/products`);

    setLoadingContratos(false);
  }

  async function exportarPontosCartao() {
    setPtsCartaoMit(true);

    await dowloadFile(`acao/${acao_id}/export/cartao-pontos`);

    setPtsCartaoMit(false);
  }

  async function exportarContratosCartao() {
    setContratosCartaoMit(true);

    await dowloadFile(`acao/${acao_id}/export/cartao`);

    setContratosCartaoMit(false);
  }

  async function exportarFuncionarios() {
    setExportFuncionarios(true);

    await dowloadFile(`/acao/${acao_id}/export/funcionarios`);

    setExportFuncionarios(false);
  }

  async function updateFinanciamentoValor(valor) {
    try {
      await api.post(`acao/${acao_id}`, {
        financiamento_valor_max: valor,
      });
      toast.success("Valor atualizado com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar valor");
      console.log(error);
    }
  }

  useEffect(() => {
    if (itemselect?.financiamento_valor_max) {
      setValueFinanciamentoValor(
        `R$${formatCurrentMoney(itemselect?.financiamento_valor_max)}`
      );
    }
  }, [itemselect?.financiamento_valor_max]);

  useEffect(() => {
    const id = admList[0]?.acao_id;
    if (id) {
      const item = select.find((item) => item.value === id);
      if (item)
        setValueFinanciamentoValor(
          `R$${formatCurrentMoney(item.financiamento_valor_max || "0")}`
        );
    }
  }, [admList, select]);

  return (
    <Container>
      <div className="container-select">
        <div style={{display:"flex",alignItems:"center",paddingLeft:"5%"}}>
          <div style={{ flex: "1" }}>
            <SelectCustom
              label={placeholder}
              options={select.length ? select : []}
              change={(value) => {
                setItemSelect(value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="detalhes">
        <div>
        </div>

        <div className="container-btns">
          {
            Object.keys(actionData).length > 0 && !loadingAcao && 
            <Button
              className="auto-fit"
              active={true}
              title="Editar Campanha"
              onClick={async () => { dispatch(AcaoAdmCreators.open_modal_update_acao()) }}
            />
          }
          {acao_type === "cartao_mit" ? (
            <>
              <Button
                active={true}
                title="Exportar Pontos"
                onClick={() => exportarPontosCartao()}
                loading={ptsCartaoMit}
              />
              <Button
                active={true}
                title="Exportar Contratos"
                onClick={() => exportarContratosCartao()}
                loading={contratosCartaoMit}
              />
            </>
          ) : null}
          {acao_type === "financiamento" ||
          tipoFinanciamento.includes(acao_type) ? (
            <>
              {/* <Button
                active={true}
                title="Exportar Pontos"
                onClick={() => exportarPontos()}
                loading={loadingPontos}
              /> */}
              <Button
                active={true}
                title="Exportar Contratos"
                onClick={() => exportarContratos()}
                loading={loadingContratos}
              />
            </>
          ) : null}

          {acao_type === "seguro" ? (
            <>
              <Button
                active={true}
                title="Exportar Carga"
                onClick={() => exportarCarga()}
                loading={loadingCarga}
              />

              <Button
                active={true}
                title="Exportar Contratos"
                onClick={() => exportarContratosSeguro()}
                loading={loadingContratos}
              />
            </>
          ) : null}

          <Button
            className="auto-fit"
            active={true}
            title="Exportar Vendedores"
            onClick={() => exportarFuncionarios()}
            loading={exportFuncionarios}
          />
          {/* <Button
            className="auto-fit"
            active={true}
            title="Funcionarios (Padrão Yetz) "
            onClick={() => exportarFuncionariosYetz()}
            loading={exportFuncionariosYetz}
          /> */}
          {/* <Button
            className="auto-fit"
            active={true}
            title="Exclusão de cargas"
            onClick={() => handleDelete(false)}
            loading={deleteCarga}
          /> */}
        </div>
      </div>

      <Modal component={ FormAcao } active={ modal_acao_update } initialValues={ actionData } />
    </Container>
  );
}

export default FiltroContrato;
