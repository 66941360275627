import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { api } from "../../services/api";
import SelectCustom from "../../components/SelectCustom";
import Button from "../../components/button";
import { Container } from "./styles";

import img from "../../assets/img/sistema/acoes.svg";
import setaImg from "../../assets/img/sistema/seta-dir.svg";
import { ObjIsEmpty } from "../../utils/helper";

function Box(props) {
  const [select, setSelect] = useState([]);
  const [itemselect, setItemSelect] = useState([]);
  const { nivel } = useSelector((state) => state.user);

  useEffect(() => {
    async function listContratos() {
      const adm = ["admin"];
      if (adm.includes(nivel)) {
        const { data } = await api.get(`acao`);

        setSelect(
          data.map((item) => {
            return { value: item.id, label: item.nome, ...item };
          })
        );
      } else {
        const { data } = await api.get(`contrato`);

        if (data.length === 0) {
          props.setModalLogout(true);
        }

        const keys = Object.keys(data);
        console.log("keys", keys);

        setSelect(
          keys.map((item, key) => {
            return { value: key, label: item, ...item };
          })
        );
      }
    }

    listContratos();
  }, [nivel, props]);

  return (
    <Container>
      <img src={img} alt="" />
      <span>Selecionar Ação</span>

      <div className="container-select">
        <SelectCustom
          label="Selecione uma ação"
          options={select}
          change={(value) => {
            setItemSelect(value);
          }}
        />

        <Link
          to={{
            pathname: `contratos`,
            itemselect,
            select,
          }}
          disabled={ObjIsEmpty(itemselect) ? true : false}
        >
          <Button title="" disabled={ObjIsEmpty(itemselect) ? true : false}>
            <img src={setaImg} alt="" />
          </Button>
        </Link>
      </div>
    </Container>
  );
}

export default Box;
